import { INPUT_UPDATE } from '../actions/index';

const initialState = {
    name: 'ODE',
  };

export default function (state = initialState, action) {
    
    switch (action.type) {
        case INPUT_UPDATE:
        return [
          { name: 'ODE' }
        ];
        default:
          return state;
    }
}
