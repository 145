/*
 * The users reducer will always return an array of users no matter what
 * You need to return something, so if there are no users then just return an empty array
 * */
const initialState = {
    modalType: null,
    modalProps: {}
};

export default function (state = initialState, action) {
    //console.log('in modal reducer');
    switch (action.type) {
        case 'SHOW_MODAL':
            return {
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps
            };
        case 'HIDE_MODAL':
            return initialState;
        default:
            return state;
    }
}
