import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { fetchCobiList, inputInitialize } from '../../actions/index';
import logo from '../../images/CFDResearch_SVG.svg';

class CobiInputParameters extends Component {

  constructor (props) {
    super()
    this.onButtonPress = this.onButtonPress.bind(this);
    this.checkRange = this.checkRange.bind(this);
  }

  //const className = `form-group ${touched && error ? "has-danger" : ""}`;
  componentWillMount() {
  }

  renderField(field) {
    const { meta: { touched, error } } = field;
    const className = `form-group ${touched && error ? "has-danger" : ""}`;

    return (
      <div className={className}>
        <label>{field.label}</label>
        <input
          className="form-control"
          type="text"
          {...field.input}
        />
        <div className="text-help">
          {field.meta.touched ? field.meta.error : ''}
        </div>
      </div>
    );
}


checkRange(text, min, max) {

   if (!isNaN(text) && text.toString().match(/-?\d*(\.\d+)?$/)) {
        const number = parseFloat(text);
        if ((number < min) || (number > max)) {
          return(`input is outside range  ${min}:${max}`);
        }
    } else {
      return "not a number";
    }
    return null;
  }

  // This function calls the server to run CoBi using the input parameters

  onButtonPress(values) {
     //const {cell, apical, basolateral } = this.props;
     //console.log(values);
     if(this.props.fetchCobiList){
       this.props.fetchCobiList(values.p1, values.p2, values.p3, values.p4, values.p5 );
     }

    }

  render() {
    //const { reset } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onButtonPress)} >
        {/* The render statements below render the user input buttons and save
        the user inputted data in a name variable instead of the displayed
      label of the component*/}

          <Field
            name="p2"
            component={this.renderField}
            label = "Body Weight (Kg)"
            type="text"
            placeholder="5"
          />
            {/**c0 5 onChangeText={value => this.onChanged('p2', value, 0.1, 100000)}**/}

          <Field
            style={styles.textView}
            name="p1"
            label = "Morphine Dose (mg)"
            component={this.renderField}
            type="text"
            keepDirtyOnReinitialize={true}
            className="form-control"

          />
            {/**logp1 3.44 onChangeText={value => this.onChanged('p1', value, -8, 8)}**/}

          <Field
            name="p3"
            component={this.renderField}
            label = 'Time Between Morphine Doses (min)'
            type="text"

            className="form-control"
          />
            {/**Vmax0 .0000029 onChangeText={value => this.onChanged('p3', value, 0, 0.01)}**/}
        <Field
          name="p4"
          component={this.renderField}
          label = 'Naloxone Dose (mg)'
          type="text"

          className="form-control"
        />

        <Field
          name="p5"
          component={this.renderField}
          label = 'Naloxone Dose Delay(min)'
          type="text"
          className="form-control"
        />

        <div flexdirection='row' style={styles.buttonView}>
        {/**<button type="button" onClick={() => inputInitialize()}>
          Load Initial Values
        </button>**/}

        {/* The buttons rendered by the following statement call the server to
        // run CoBi*/}
        
          <button type="submit" className="btn btn-primary" style={styles.button} > Submit </button>
          <button type="reset" className="btn btn-primary" style={styles.button} > Reset </button>
      </div>

      {/* Renders the  CFD Research Logo below the buttons*/}

      <div style={styles.imgViewLeft}>
        <img style={styles.imageView} src={logo} alt="CFD Research" />
      </div>
      </form>
    );
  }
}

function checkRange(text, min, max) {
   if (!isNaN(text) && text.toString().match(/-?\d*(\.\d+)?$/)) {
        const number = parseFloat(text);
        if ((number < min) || (number > max)) {
          return(`input is outside range  ${min}:${max}`);
        }
    } else {
      return "not a number";
    }
    return null;
  }

function validate(values) {
  // console.log(values) -> { title: 'asdf', categories: 'asdf', content: 'asdf' }
  const errors = {};
  // Validate the inputs from 'values'
  if (!values.p1) {
    errors.p1 = "Enter first parameter";
  }
  if (!values.p2) {
    errors.p2 = "Enter second parameter";
  }
  if (!values.p3) {
    errors.p3 = "Enter third parameter";
  }
  if (!values.p4) {
    errors.p4 = "Enter fourth parameter";
  }
  /**if (values.p3){
    const rangeError = checkRange(values.p3, 300, 500);
    if (rangeError != null){
        errors.p3 = rangeError;
    }
  }

  if (values.p4){
      const rangeError = checkRange(values.p4, 1000, 1500);
      if (rangeError != null){
        errors.p4 = rangeError;
      }
  }
  if (values.p5){
    const rangeError = checkRange(values.p5, 5, 1500);
    if (rangeError != null){
      errors.p5 = rangeError;
    }
  }**/
  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}

function mapStateToProps(state) {
  return {
    initialValues: state.cobiInput
  };
}

CobiInputParameters = reduxForm({
  validate,
  form: 'CobiInputParametersForm',
  enableReinitialize: true,
  fields: ['p1', 'p2', 'p3', 'p4', 'p5'],
})(CobiInputParameters);

// You have to connect() to any reducers that you wish to connect to yourself
CobiInputParameters = connect(
    mapStateToProps, { fetchCobiList, inputInitialize })(CobiInputParameters);

export default CobiInputParameters;

// Styles used to define the behavior of the cmomponents in the DIV refrenced

const styles = {

  textView:{
    textAlign: "left",
    justifyContent: 'space-around',
    width: '50%',
    height: 30,
    marginRight: 10,
    margingLeft: 10,
  },
  buttonView: {
    justifyContent: 'space-around',
    paddingTop: 2,
    width: '100%',
    height: 40,
  },
  button:{
    width: '40%',
    marginRight: 10,
    margingLeft: 10,
    textAlign: "center",
  },
  graphViewRight: {
        float: 'right',
        width: '100%',
        height: '100%',
  },
  imageView: {
        height: '100%',
        //width: '115%',
        backgroundSize: 'auto',
  },
}
