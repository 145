import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter } from "react-router-dom";
import promise from "redux-promise";

import reducers from "./reducers";

import App from "./components/App";

//import Background from './images/cfdrcBlue.jpg';

const createStoreWithMiddleware = applyMiddleware(promise)(createStore);
//<Route path="/posts/:id" component={PostsShow} />
ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <BrowserRouter >
          <App />
    </BrowserRouter>
  </Provider>,
  //document.querySelector(".root")
  document.getElementById('MyContainer')
);
