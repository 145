
import axios from 'axios';
//export const localhost = 'https://opioids2.cfapps.io';
//export const localhost = 'https://opioid.cfapps.io';
//export const localhost = 'http://localhost:5000';
export const localhost = 'https://cobi-api.health-map.net:443';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const USER_UPDATE = 'USER_UPDATE';
export const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT';
export const SET_TOPIC = 'SET_TOPIC';
export const FETCH_COBILIST = 'FETCH_COBILIST';
export const FETCH_COBIMASTERLIST = 'FETCH_COBIMASTERLIST';
export const INPUT_UPDATE = 'INPUT_UPDATE';
export const INPUT_INITIALIZE = 'INPUT_INITIALIZE';

let context = 'topicOfWeblet';
export function toggleModal(ms) {
//console.log('in toggle model action');
    return {
        type: ms.type,
        payload: ms
    };
}

export function toggleLayout(layout) {
//console.log('change layout in index');
    return {
        type: TOGGLE_LAYOUT,
        payload: layout
    };
}

export function setTopic(topic) {
	context = topic;
	//console.log(topic);
}

export function navigateToTopic(topic) {
	context = topic;
	//console.log(topic);
	if (topic === 'DRUG ANALYSIS') {
    //Actions.druganalysis();
  }
}

export function fetchCobiList(p1, p2, p3, p4, p5) {
	const COBITEST_URL = `${localhost}/cobi/opioid/runCobiTest`;
  //const user = firebase.auth().currentUser.uid;
  const user = 'user';
  const context1 = 'topic';

	const url =
   `${COBITEST_URL}?user=${user}&topic=${context1}&p1=${p1}&p2=${p2}&p3=${p3}&p4=${p4}&p5=${p5}`;

  const request = axios.get(url);
  return{
		type:FETCH_COBILIST,
		payload: request
	};
}


  
export function loadData() {
	const LOAD_DATA_URL = `${localhost}/cobi/opioid/loadData`;
  
  const user = 'user';
  const context1 = 'topic';
  const project = 'Opioid'

	const url = `${LOAD_DATA_URL}?user=${user}&topic=${context1}&project=${project}`;

  const request = axios.get(url);
  return{
		type:FETCH_COBILIST,
		payload: request
	};
}

export const inputUpdate = ({ prop, value }) => {
//  console.log("in input update")
  return {
    type: INPUT_UPDATE,
    payload: { prop, value }
  };
};

export const inputInitialize = () => {
//  console.log("in input update")
  return {
    type: INPUT_INITIALIZE,
    payload: {}
  };
};
