
import { combineReducers } from 'redux';
import CobiListReducer from './reducer-cobilist';
import ModalReducer from './reducer-modals';
import ToggleLayoutReducer from './reducer-toggle-layout';
import TopicsReducer from './reducer-topicList';
import CobiInputReducer from './reducer-cobiInput';
import CobiMasterListReducer from './reducer-cobiMasterList';
import { reducer as formReducer } from 'redux-form';

/*
 * We combine all reducers into a single object before updated data is dispatched (sent) to store
 * Your entire applications state (store) is just whatever gets returned from all your reducers
 * */

export default combineReducers({
   modalState: ModalReducer,
   layout: ToggleLayoutReducer,
   topicList: TopicsReducer,
   cobiList: CobiListReducer,
   cobiInput: CobiInputReducer,
   cobiMaserList: CobiMasterListReducer,
   form : formReducer,
});
