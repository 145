//import { width, height } from 'react-native-dimension';
import { TOGGLE_LAYOUT } from '../actions/index';

const initialState = {
    layout: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    }
  };

export default function (state = initialState, action) {
    //console.log('*****in toggle layout reducer*******');
    switch (action.type) {
        case TOGGLE_LAYOUT:
            return action.payload;
    default:
            return state;
    }
}
