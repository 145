import {
  FETCH_COBILIST
 } from '../actions/index';

export default function (state = [], action) {
//console.log('in time list reducer', action);
    switch (action.type) {
      case FETCH_COBILIST:
          return action.payload.data;
      default:
        return state;
    }
}
