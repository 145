import { INPUT_UPDATE, INPUT_INITIALIZE } from '../actions/index';

const initialState = {
    p1: '10.35',  //morphine dose (mg)
    p2: '70', // mass (g)
    p3: '240', // morphine dose frequency ()
    p4: '0.2', // naloxone dose
    p5: '30', // Naloxone Delay (min)
  };

export default function (state = initialState, action) {

    switch (action.type) {
        case INPUT_UPDATE:
          return { ...state, [action.payload.prop]: action.payload.value };
        case INPUT_INITIALIZE:
            return { initialState };
        default:
          return state;
    }
}
