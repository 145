import React from 'react';
import { connect } from 'react-redux';
import { VictoryLine, VictoryChart, VictoryLabel, VictoryAxis } from 'victory';

class LineChart extends React.Component {

  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.getLabelY = this.getLabelY.bind(this);
    this.getLog = this.getLog.bind(this);
    this.selectedOption = 'Linear';
    this.handleOptionChangeLin = this.handleOptionChangeLin.bind(this);
    this.handleOptionChangeLog = this.handleOptionChangeLog.bind(this);
  }

  handleOptionChangeLin(changeEvent) {
    this.selectedOption = 'Linear';
    console.log('check1');
  }
  handleOptionChangeLog(changeEvent) {
    this.selectedOption = 'Logarithmic';
  }

  // This function interprets the returned CoBi outputs and sorts them into
  // each graph.

  getData() {
    let data = [];
    let dataRecord = {};
    if ((this.props === null) || (this.props.cobiList === null)) return null;
    if (this.props.index === 1) {
      if (this.props.cobiList != null) {
        for (let i = 0; i < this.props.cobiList.length; i++) {
          dataRecord = this.props.cobiList[i];
          const xValue = dataRecord.time;
          const yValue = dataRecord.out1;
          data.push({ x: xValue, y: yValue });
        }
      }
    } else if (this.props.index === 2) {
      if (this.props.cobiList != null) {
        for (let i = 0; i < this.props.cobiList.length; i++) {
          dataRecord = this.props.cobiList[i];
          const xValue = dataRecord.time;
          const yValue = dataRecord.out3;
          data.push({ x: xValue, y: yValue });
        }
      }
    } else if (this.props.index === 3) {
      if (this.props.cobiList != null) {
        for (let i = 0; i < this.props.cobiList.length; i++) {
          dataRecord = this.props.cobiList[i];
          const xValue = dataRecord.time;
          const yValue = dataRecord.out2;
          data.push({ x: xValue, y: yValue });
        }
      }
    } else if (this.props.index === 4) {
      if (this.props.cobiList != null) {
        for (let i = 0; i < this.props.cobiList.length; i++) {
          dataRecord = this.props.cobiList[i];
          const xValue = dataRecord.time;
          const yValue = dataRecord.out4;
          data.push({ x: xValue, y: yValue });
        }
      }
    } else if (this.props.index === 5) {
      if (this.props.cobiList != null) {
        for (let i = 0; i < this.props.cobiList.length; i++) {
          dataRecord = this.props.cobiList[i];
          const xValue = dataRecord.time;
          const yValue = dataRecord.out6;
          data.push({ x: xValue, y: yValue });
        }
      }
    } else if (this.props.index === 6) {
      if (this.props.cobiList != null) {
        for (let i = 0; i < this.props.cobiList.length; i++) {
          dataRecord = this.props.cobiList[i];
          const xValue = dataRecord.time;
          const yValue = dataRecord.out5;
          data.push({ x: xValue, y: yValue });
        }
      }
    }
    return data;
  }

  // This Function returns the Title associated with the index given.

  getTitle() {
    let title = "";
    if (this.props.index === 1) {
      title = "Plasma Morphine Concentration"
    } else if (this.props.index === 2) {
      title = "Brain Morphine Concentration"
    } else if (this.props.index === 3) {
      title = "Plasma Nalaxone Concentration"
    } else if (this.props.index === 4) {
      title = "Brain Nalaxone Concentration"
    } else if (this.props.index === 5) {
      title = "Ventilation Change"
    } else if (this.props.index === 6) {
      title = "Bound Morphine"
    }

    return title;
  }

  // This Function returns the Y axis label for the graph chosen by the index.

  getLabelY() {
    let labely = "";
    if (this.props.index === 1) {
      labely = "Concentration (uM)"
    } else if (this.props.index === 2) {
      labely = "Concentration (uM)"
    } else if (this.props.index === 3) {
      labely = "Concentration (uM)"
    } else if (this.props.index === 4) {
      labely = "Concentration (uM)"
    } else if (this.props.index === 5) {
      labely = "Fractional Ventilation Change"
    } else if (this.props.index === 6) {
      labely = "Factional Bound Morphine"
    }

    return labely;
  }

  // This Function will change the Y axis scaling of the graph with the selected
  // index.

  getLog() {
    let log = "linear";
    if (this.props.index === 1) {
      log = "log";
    }
    return log;
  }

  render() {
    let data = [];
    let theTitle = "Title";
    let labelY = "Label";
    const color = this.props.color;
    data = this.getData();
    theTitle = this.getTitle();
    labelY = this.getLabelY();
    this.log = this.getLog();
    return (


      <VictoryChart
        height={300}
        style={styles.victoryChartView}
        color={"white"}
        padding={{ left: 80, right: 20, top: 50, bottom: 50 }}
        scale={{ x: "linear", y: this.log }}
      //domain={{y:[this.ymin, this.ymax]}}
      //domainPadding={{ y: 50, x: 200 }}
      >

        {/* This render statement determines the Y axis appearance */}

        <VictoryAxis dependentAxis
          style={{
            axis: { stroke: "white" },
            tickLabels: { fill: "white" },
            axisLabel: { fontSize: 14, padding: 0 },
          }}
        />

        {/* This render statement determines the x axis appearance */}

        <VictoryAxis crossAxis
          style={{
            axis: { stroke: "white" },
            tickLabels: { fill: "white" },
            axisLabel: { fontSize: 14, padding: 30 },
          }}
        />

        {/* This render statement defines the Title of the graphs */}

        <VictoryLabel
          style={{ fill: "white" }}
          fontSize={30}
          x={165} y={10}
          text={theTitle}
        />

        {/* This render statement defines the Y axis label of the graphs */}

        <VictoryLabel
          style={{  fill: "white" }}
          x={15} y={225}
          text={labelY}
          angle={270}
        />

        {/* This render statement defines the X axis label of the graphs */}

        <VictoryLabel
          style={{ fontSize: 14, fill: "white" }}
          x={250} y={290}
          text={'Time (min)'}
        />
        <VictoryLine
          style={{
            data: { stroke: color, strokeWidth: 2 },
            marginLeft: 10,
            marginRight: 10,
            marginTop: 20,
            strokeWidth: 1,
            parent: { border: "1px solid #ccc" },
            width: 100,
            height: '100%',
          }}
          padding={{ top: 0, bottom: 0, left: 10, right: 50 }}
          interpolation="natural"
          data={data}
        />
      </VictoryChart>
    );
  }
}
//export default AreaChart;
export default connect(mapStateToProps, null)(LineChart);

// makes the container
function mapStateToProps(state) {

  return {
    cobiList: state.cobiList
  };
}

// The styles array further specifies render of the corresponding render

const styles = {
  victoryChartView: {
    width: '60%',
    //height: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    data: { stroke: "#c43a31", strokeWidth: 1 },
    parent: { border: "1px solid #015f82" }
  },

  victoryLineChartView: {
    width: '60%',
    //height: '90%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    strokeWidth: 5,
    padding: { top: 0, bottom: 0, left: 20, right: 0 },
    parent: { border: "1px solid #ccc" }
  },

  radioButtonView: {
    width: '30%',
    height: '10%',
  },

  graphView: {
    width: '100%',
    height: '100%',
  },
}
