import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchCobiList, loadData } from '../actions/index';
import LineChart from './charts/lineChart';

class CobiGraph extends Component {

  constructor(props) {
    super(props);

    this.selectedOption = 'Linear'
    const margin1 = 30;
    const numberOfIncrements = 10;
    const boxWidth = (this.props.timeListWidth - (2 * margin1)) / numberOfIncrements;
    const boxHeight = 20;
    this.state = {
      screen: {
        width: this.props.timeListWidth,
        height: this.props.timeListHeight,
        ratio: 1,
        boxwidth: boxWidth,
        boxheight: boxHeight,
        margin: margin1,
        axisStartY: 150,
        strokeW: 2,

      },
    };
  }

  /**componentWillMount() {
    if (this.props.fetchCobiList){
      this.props.fetchCobiList( this.props.p1,
        this.props.p2,
        this.props.p3,
        this.props.p4,
        this.props.p5,
        this.props.p6,
        this.props.time);
    }
  }**/

  componentWillMount() {
      this.props.loadData( );
  }

  render() {

    if ((this.props.cobiList == null) || (this.props.cobiList.length === 0)) {
      return (null);
    }

    return (
      <div style={styles.graphViewMain}>
        <div style={styles.graphViewRow}>
          <div style={styles.graphViewLeft}>
            <LineChart index={1} color="red" />
          </div>
          <div style={styles.graphViewRight}>
            <LineChart index={2} color="blue" />
          </div>
        </div>
        <div style={styles.graphViewRow }>
          <div style={styles.graphViewLeft}>
            <LineChart index={3} color="#01ad0d"/>
          </div>
          <div style={styles.graphViewRight}>
            <LineChart index={4} color="yellow"/>
          </div>
        </div>
        <div style={styles.graphViewRow }>
          <div style={styles.graphViewLeft}>
            <LineChart index={5} color="purple"/>
          </div>
          <div style={styles.graphViewRight}>
            <LineChart index={6} color="orange"/>
          </div>
        </div>
        <div style={styles.disclaimer}>
          <p>Disclaimer: This model is intended for research and education. It
          is not intended for clinical use.</p>
        </div>
      </div>
    );
  }
}

// makes the container
function mapStateToProps(state) {
  const { p1, p2, p3, p4, p5, p6, time } = state.cobiInput;
  return {
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    time,
    cobiList: state.cobiList
  };
}

function mapDispatchToProps(dispatch) {
  //console.log("in dispatchtoprops"+dispatch);
  return bindActionCreators({ fetchCobiList, loadData }, dispatch);
}

//export default TimeList;
export default connect(mapStateToProps, mapDispatchToProps)(CobiGraph);

const styles = {
  buttonView: {
    //backgroundColor: '#008080',
    width: '100%',
    height: 40,
  },

  legendContainer: {
    width: '95%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  legendSubContainer: {
    width: '33%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  legendText: {
      fontSize: 14,
      color: 'white',
    },

  graphViewMain: {
      width: '100%',
      height: '95%',
      flexDirection: 'column',
    },

  graphViewRow: {
      width: '100%',
      height: '34.25%',
      //height: '44.25%',
      flexDirection: 'row',
    },

  radioButtonView: {
    width: '20%',
    height: '100%',
    flexDirection: 'column',
  },

  graphViewLeft: {
        float: 'left',
        width: '50%',
        height: '100%',
        flexDirection: 'row',
      },

  graphViewRight: {
        float: 'right',
        width: '50%',
        height: '100%',
        },

  imageViewRight: {
        height: '125%',
        backgroundSize: 'auto',
        },
  disclaimer: {
        color: 'white',
  },
}
